.grid_container {
    background-color: #007bff;
}

.container {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgb(167, 229, 231);
    padding-left: 10px;
    position: fixed;
    margin-top: 45px;
    z-index: 5;
}

.card {
    padding-left: 5px;
    opacity: 0.99;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 9px;
    width: 650px;
    border-radius: 5px 5px 0px 0px;
}

.buttonBox {
    padding-left: 5px;
    opacity: 0.99;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 9px;
    width: 650px;
    border-radius: 0px 0px 5px 5px;
}

.inputPrepend {
    height: 38px;
    margin-top: 8px;
    background-color: aquamarine;
}

.span {
    position: absolute;
    display: inline-block;
    height: 38px;
    background-color: rgb(167, 229, 231);
    margin-left: 22px;
}

.icon {
    position: relative;
    color: rgb(69, 158, 161);
}

.grid_container {
    display: flex;
    flex-wrap: wrap;
    /* grid-row-gap: 5px;
    grid-template-columns: 650px 170px 322px auto; */
    padding: 10px;
    margin-bottom: -58px;
    position: relative;
    z-index: 3;
}

.grid_container>* {
    flex: 1 1 10em;
}

.label {
    margin-left: 25px;
    margin-bottom: -15px;
}

.dropBtn {
    color: red;
}

.Drop {
    position: absolute;
    opacity: 0.92;
    z-index: 4;
    margin-top: -1px;
}

.dropdownShow {
    background-color: #0062cc;
    padding-bottom: 15px;
    margin-top: -12px;
    margin-bottom: -7px;
    border-radius: 0 5px 5px 5px;
}

.row {
    width: 620px;
}

@media only screen and (max-width: 1390px) {
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 450px;
        border-radius: 0px 0px 0px 0px;
    }
    .row {
        width: 470px;
    }
    .buttonBox {
        width: auto;
        border-radius: 0px 0px 0px 0px;
    }
}

@media only screen and (max-width: 500px) {
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 250px;
        border-radius: 0px 0px 0px 0px;
    }
    .row {
        width: 280px;
    }
    .buttonBox {
        width: auto;
        border-radius: 0px 0px 0px 0px;
    }
}

@media only screen and (max-width: 300px) {
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 250px;
        border-radius: 0px 0px 0px 0px;
    }
    .row {
        width: 280px;
    }
    .buttonBox {
        width: auto;
        border-radius: 0px 0px 0px 0px;
    }
}


/* @media only screen and (max-width: 1670px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 820px 170px 350px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1650px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 720px 250px 350px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1520px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 680px 260px 310px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1460px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 580px 350px 180px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1350px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 490px 420px 130px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1260px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 390px 560px 20px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
} */