.container {
    width: 100%;
    height: 110%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    padding-top: 25px;
    padding-bottom: 350px;
    padding-right: 25px;
}

.card {
    margin: 15px;
    opacity: 0.75;
    width: 500px;
    padding: 15px;
    background: white;
    border-radius: 5px;
}