.ButtonBordered {
    background-color: transparent;
    border: 2px solid rgb(167, 229, 231);
    border-radius: 5px;
    color: white;
    outline: invert;
    cursor: pointer;
    font: inherit;
    padding: 3px 3px;
    margin-left: 5px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    position: relative;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    text-decoration: none;
    overflow: hidden;
    width: 75px;
    height: 35px;
}

.ButtonBordered:after {
    content: "";
    background: #115ae2;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px!important;
    margin-top: -120%;
    opacity: 0;
    transition: all 0.8s
}

.ButtonBordered:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s
}

.ButtonBordered:first-of-type {
    margin-left: 22px;
    padding-left: 5px;
}

.ButtonBordered:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    /* color: #5C9210; */
    color: white;
}

.Danger {
    /* color: #944317; */
    color: red;
}

.Secondary {
    color: rgb(132, 197, 211);
    /* -webkit-text-stroke: 1px white; */
}

@media only screen and (max-width: 300px) {
    .ButtonBordered {
        width: 60px;
        height: 29px;
        margin-bottom: 5px;
        margin-right: -2px;
        padding-bottom: 5px;
        text-align: center;
    }
}