.container {
    display: block;
    text-align: center;
    z-index: 1;
}

.form {
    margin-top: 35px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.dateTime {
    padding-left: 2px;
    opacity: 0.99;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
    width: 365px;
    border-radius: 5px 5px 0px 0px;
}

.inputPrepend {
    height: 38px;
    margin-top: 8px;
    background-color: aquamarine;
}

.span {
    position: absolute;
    display: inline-block;
    height: 38px;
    background-color: rgb(167, 229, 231);
    margin-left: 22px;
}

.icon {
    position: relative;
    color: rgb(69, 158, 161);
}

.timeMin {
    margin-bottom: 12px;
    width: 350px;
    margin-left: 5px;
}

.timeMax {
    margin-bottom: 12px;
    max-width: 350px;
    min-width: 350px;
    height: 122px;
    margin-left: 5px;
    word-wrap: break-word;
    display: inline-block;
    text-align: start;
    overflow-y: auto;
    overflow-wrap: break-word
}

.btnContainer {
    display: flex;
    width: 250px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.btnContainer>* {
    flex: 1 1 50%;
    margin-top: 25px;
}

.button {
    margin-bottom: 5px;
}

.formControl {
    margin-top: 12px;
    margin-right: 50%;
    margin-left: 35%;
    width: 380px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.resultLabel {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
}

.input {
    width: 258px;
    margin-left: 55px;
    margin-right: auto;
    height: 38px;
    margin-top: 15px;
}

.label {
    margin: auto;
    margin-top: -5px;
}

.resultBox {
    width: 258px;
}

.highlight {
    background-color: rgb(219, 239, 255) !important;
    transition: background-color 2s ease-in-out !important;
}

.dehighlight {
    background-color: transparent !important;
    transition: background-color 2s ease-in-out !important;
}


@media (max-width: 1683px) {
  th, td{
      font-size: 13.5px;
      padding: 0.25rem!important;
  }

}
