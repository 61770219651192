.container {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    position: absolute;
    left: 0px;
    z-index: 9;
    margin-top: 4.5px;
}

.card {
    padding-left: 2px;
    opacity: 0.99;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 2px;
    width: 252px;
    border-radius: 5px 5px 0px 0px;
}

.bar {
    margin-right: -70px;
}

.inputGroup {
    margin-right: 15px;
    width: 252px;
}

.buttonBox {
    padding-left: 5px;
    opacity: 0.99;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 9px;
    width: 333px;
    border-radius: 0px 0px 5px 5px;
}

.inputPrepend {
    height: 38px;
    margin-top: 8px;
    background-color: aquamarine;
}

.span {
    position: absolute;
    display: inline-block;
    height: 38px;
    background-color: rgb(167, 229, 231);
    margin-left: 22px;
}

.span2 {
    position: absolute;
    display: inline-block;
    height: 34px;
    background-color: rgb(167, 229, 231);
    margin-left: 22px;
}

.icon {
    position: relative;
    color: rgb(69, 158, 161);
}

.Drop {
    position: absolute;
    /* position: -webkit-sticky;
    position: sticky; */
    left: 15px;
    opacity: 0.92;
    z-index: 9;
    white-space: normal;
    word-break: break-all;
    margin-left: 5px;
    margin-top: -2px;
}

.dateTime {
    font-size: 12px;
    width:400px;
}

.dropdownShow {
    background-color: #0062cc;
    padding-bottom: 15px;
    margin-top: -12px;
    margin-bottom: -7px;
    border-radius: 0 5px 5px 5px;
    width: 1560px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.inputApend {
    margin-top: -46px;
}

.existsBtn {
    font-size: small;
    height: 39.5px;
    margin-top: 7.5px;
    margin-left: -51px;
    background-color: yellow;
    color: #0062cc;
    width: 52px;
    border: 1px solid #0062cc;
}

@media only screen and (max-width: 1582px) {
    .card {
        padding-left: 2px;
        opacity: 0.99;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 2px;
        width: 200px;
        border-radius: 5px 5px 0px 0px;
    }
    .bar {
        margin-right: -70px;
    }
    .inputGroup {
        margin-right: 12px;
        width: 200px;
        font-size: 5px !important;
    }
    .dropdownShow {
        background-color: #0062cc;
        padding-bottom: 15px;
        margin-top: -12px;
        margin-bottom: -7px;
        border-radius: 0 5px 5px 5px;
        width: 1250px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 1200px) {
    .card {
        padding-left: 2px;
        opacity: 0.99;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 2px;
        width: 200px;
        border-radius: 5px 5px 0px 0px;
    }
    .bar {
        margin-right: -70px;
    }
    .inputGroup {
        margin-right: 12px;
        width: 200px;
    }
    .dropdownShow {
        background-color: #0062cc;
        padding-bottom: 15px;
        margin-top: -12px;
        margin-bottom: -7px;
        border-radius: 0 5px 5px 5px;
        width: 560px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 650px) {
    .card {
        padding-left: 2px;
        opacity: 0.99;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 2px;
        width: 200px;
        border-radius: 5px 5px 0px 0px;
    }
    .bar {
        margin-right: -70px;
    }
    .inputGroup {
        margin-right: 12px;
        width: 200px;
        font: 100 !important;
    }
    .dropdownShow {
        background-color: #0062cc;
        padding-bottom: 15px;
        margin-top: -12px;
        margin-bottom: -7px;
        border-radius: 0 5px 5px 5px;
        width: 360px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 450px) {
    .card {
        padding-left: 2px;
        opacity: 0.99;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-right: 2px;
        width: 250px;
        border-radius: 5px 5px 0px 0px;
    }
    .bar {
        margin-right: -70px;
    }
    .inputGroup {
        margin-right: 12px;
        width: 250px;
        font-size: xx-small;
    }
    .dropdownShow {
        background-color: #0062cc;
        padding-bottom: 15px;
        margin-top: -12px;
        margin-bottom: -7px;
        border-radius: 0 5px 5px 5px;
        width: 260px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .existsBtn {
        font-size: 3px;
        height: 39.5px;
        margin-top: 7.5px;
        margin-left: -51px;
        background-color: yellow;
        color: #0062cc;
        width: 52px;
        border: 1px solid #0062cc;
    }
}

@media only screen and (max-width: 362px) {
    .Drop {
        position: absolute;
        opacity: 0.92;
        z-index: 5;
        white-space: normal;
        word-break: break-all;
        margin-left: 5px;
        margin-top: -2px;
    }
    .bar {
        margin-right: -75px;
    }
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 250px;
        border-radius: 0px 0px 0px 0px;
    }
    .dropdownShow {
        background-color: #0062cc;
        padding-bottom: 15px;
        margin-top: -12px;
        margin-bottom: -7px;
        border-radius: 0 5px 5px 5px;
        width: 270px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 315px) {
    .Drop {
        position: absolute;
        opacity: 0.92;
        z-index: 7;
        white-space: normal;
        word-break: break-all;
        margin-left: 5px;
        margin-top: -2px;
    }
    .bar {
        margin-right: -75px;
    }
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 250px;
        border-radius: 0px 0px 0px 0px;
    }
    .dropdownShow {
        background-color: #0062cc;
        padding-bottom: 15px;
        margin-top: -12px;
        margin-bottom: -7px;
        border-radius: 0 5px 5px 5px;
        width: 270px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 220px) {
    .bar {
        margin-right: -75px;
    }
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 250px;
        border-radius: 0px 0px 0px 0px;
    }
    .dropdownShow {
        background-color: #0062cc;
        padding-bottom: 15px;
        margin-top: -12px;
        margin-bottom: -7px;
        border-radius: 0 5px 5px 5px;
        width: 270px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}