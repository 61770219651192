.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 80%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 5px;
    height: 500px;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}

@media (max-height: 700px) {
    .Modal {
        position: fixed;
        top: 0%;
    }
}

@media (max-height: 500px) {
    .Modal {
        position: fixed;
        top: -10%;
    }
}

@media (max-height: 450px) {
    .Modal {
        position: fixed;
        top: -20%;
    }
}

@media (max-height: 420px) {
    .Modal {
        position: fixed;
        top: -30%;
    }
}

@media (max-height: 385px) {
    .Modal {
        position: fixed;
        top: -40%;
    }
}

@media (max-height: 355px) {
    .Modal {
        position: fixed;
        top: -50%;
    }
}

@media (max-height: 330px) {
    .Modal {
        position: fixed;
        top: -60%;
    }
}

@media (max-height: 310px) {
    .Modal {
        position: fixed;
        top: -70%;
    }
}