.container {
    display: block;
    text-align: center;
    z-index: 1;
}

.form {
    margin-top: 35px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.input {
    margin-bottom: 12px;
    width: 350px;
    margin-left: 5px;
}

.input1 {
    margin-bottom: 12px;
    max-width: 350px;
    min-width: 350px;
    height: 122px;
    margin-left: 5px;
    word-wrap: break-word;
    display: inline-block;
    text-align: start;
    overflow-y: auto;
    overflow-wrap: break-word
}

.btnContainer {
    display: flex;
    width: 250px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.btnContainer>* {
    flex: 1 1 50%;
}

.button {
    margin-bottom: 5px;
}

.formControl {
    margin-top: 12px;
    margin-right: 50%;
    margin-left: 35%;
    width: 380px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.resultLabel {
    margin-top: 15px;
    font-weight: bold;
}