.Auth {
    margin: 170px auto;
    width: 80%;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 15px;
    box-sizing: border-box;
    background: rgb(167, 229, 231);
    border-radius: 5px;
}

@media (min-width: 600px) {
    .Auth {
        width: 500px;
    }
}