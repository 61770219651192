.container {
    display: block;
    text-align: center;
}

.form {
    margin-top: 55px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.input {
    margin-bottom: 12px;
    width: 400px;
    /*margin-left: 5px;*/
}

.btnContainer {
    display: flex;
    width: 250px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.btnContainer>* {
    flex: 1 1 50%;
}

.button {
    margin-bottom: 5px;
}

.fieldContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    text-align: left;
}

label{
    margin-bottom: 0px;
}