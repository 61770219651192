.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem a {
    /* color: #8F5C2C; */
    /* color: salmon; */
    color: lightslategrey;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    /* color: #40A4C8; */
    color: rgb(0, 112, 192);
    /* border-right: 12px solid rgb(252, 242, 34); */
    border-right: 12px solid rgb(0, 112, 192);
}

@media (min-width: 1055px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    .NavigationItem a {
        color: white;
        /* color: rgb(252, 242, 34); */
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        /* background-color: #8F5C2C; */
        background-color: rgb(167, 229, 231);
        /* border-bottom: 4px solid #40A4C8; */
        border-bottom: 4px solid rgb(0, 112, 192);
        border-right: none;
        /* color: white; */
        /* color: rgb(252, 242, 34); */
        color: darkblue;
    }
}