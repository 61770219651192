.container {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgb(167, 229, 231);
    padding-left: 10px;
    position: fixed;
    margin-top: 45px;
    z-index: 5;
}

.card {
    padding-left: 5px;
    opacity: 0.99;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 9px;
    width: 650px;
    border-radius: 5px 5px 0px 0px;
}

.buttonBox {
    padding-left: 5px;
    opacity: 0.99;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 9px;
    width: 433px;
    border-radius: 0px 0px 5px 5px;
}

.inputPrepend {
    height: 38px;
    margin-top: 8px;
    background-color: aquamarine;
}

.span {
    position: absolute;
    display: inline-block;
    height: 38px;
    background-color: rgb(167, 229, 231);
    margin-left: 22px;
}

.span2 {
    position: absolute;
    display: inline-block;
    height: 40px;
    background-color: rgb(167, 229, 231);
    margin-left: 22px;
}

.icon {
    position: relative;
    color: rgb(69, 158, 161);
}

.Drop {
    position: absolute;
    opacity: 0.92;
    z-index: 4;
    margin-top: -1px;
}

.dropdownShow {
    background-color: #0062cc;
    padding-bottom: 15px;
    margin-top: -12px;
    margin-bottom: -7px;
    border-radius: 0 5px 5px 5px;
}

.row {
    width: 620px;
}

@media only screen and (max-width: 1390px) {
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 450px;
        border-radius: 0px 0px 0px 0px;
    }
    .row {
        width: 470px;
    }
    .buttonBox {
        width: auto;
        border-radius: 0px 0px 0px 0px;
    }
}

@media only screen and (max-width: 500px) {
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 250px;
        border-radius: 0px 0px 0px 0px;
    }
    .row {
        width: 280px;
    }
    .buttonBox {
        width: auto;
        border-radius: 0px 0px 0px 0px;
    }
    .span2 {
        height: 62px;
    }
}

@media only screen and (max-width: 300px) {
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 250px;
        border-radius: 0px 0px 0px 0px;
    }
    .row {
        width: 280px;
    }
    .buttonBox {
        width: auto;
        border-radius: 0px 0px 0px 0px;
    }
}