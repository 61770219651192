.Input {
    width: 79%;
    box-sizing: border-box;
    padding-left: 32px;
    margin-left: 22px;
}

input[type="date"]:before {
    content: attr(placeholder) !important;
    color: gray;
    margin-right: 0.7em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
    content: "";
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.InputElement {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #ccc;
}

.Invalid {
    border: 1px solid red;
    background-color: #FDA49A;
}


/* @media only screen and (max-width: 876px) {
    .InputElement {
        font-size: small;
        color: brown;
    }
} */