.container {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: 45px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgb(167, 229, 231);
    padding-left: 10px;
    position: absolute;
    margin-top: 45px;
    z-index: 5;
}

.card {
    padding-left: 5px;
    opacity: 0.99;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: -22px;
    width: 290px;
    border-radius: 5px 5px 0px 0px;
}

.buttonBox {
    padding-left: 5px;
    opacity: 0.99;
    padding-top: 2px;
    padding-bottom: 2px;
    margin-left: 9px;
    width: 300px;
    border-radius: 0px 0px 5px 5px;
}

.inputPrepend {
    height: 38px;
    margin-top: 8px;
    background-color: aquamarine;
}

.span {
    position: absolute;
    display: inline-block;
    height: 38px;
    background-color: rgb(167, 229, 231);
    margin-left: 22px;
}

.icon {
    position: relative;
    color: rgb(69, 158, 161);
}

.label {
    margin-left: 25px;
    margin-bottom: -15px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.Drop {
    position: absolute;
    left: 15px;
    opacity: 0.92;
    z-index: 9;
    white-space: normal;
    word-break: break-all;
    margin-left: 5px;
    margin-top: -2px;
}

.dropdownShow {
    background-color: #0062cc;
    padding-bottom: 15px;
    margin-top: -11px;
    margin-bottom: -7px;
    border-radius: 0 5px 5px 5px;
    width: 540px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.bar {
    margin-right: -50px;
}

@media only screen and (max-width: 560px) {
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 330px;
        border-radius: 0px 0px 0px 0px;
    }
    .dropdownShow {
        background-color: #0062cc;
        padding-bottom: 15px;
        margin-top: -11px;
        margin-bottom: -7px;
        border-radius: 0 5px 5px 5px;
        width: 330px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .row {
        width: 340px;
    }
    .buttonBox {
        width: auto;
        border-radius: 0px 0px 0px 0px;
    }
}

@media only screen and (max-width: 300px) {
    .container {
        padding-left: 5px;
        padding-right: 20px;
        height: auto;
        padding-bottom: 20px;
        height: 45px;
    }
    .card {
        width: 250px;
        border-radius: 0px 0px 0px 0px;
    }
    .row {
        width: 280px;
    }
    .buttonBox {
        width: auto;
        border-radius: 0px 0px 0px 0px;
    }
    .dropdownShow {
        background-color: #0062cc;
        padding-bottom: 15px;
        margin-top: -11px;
        margin-bottom: -7px;
        border-radius: 0 5px 5px 5px;
        width: 270px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}


/* @media only screen and (max-width: 1670px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 420px 170px 450px 450px;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1650px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 320px 250px 420px 450px;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1520px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 260px 290px 330px 490px;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1460px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 240px 350px 200px 590px;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1350px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 220px 420px 130px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1260px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 220px 350px 90px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1260px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 220px 350px 90px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
}

@media only screen and (max-width: 1050px) {
    .grid_container {
        display: grid;
        grid-row-gap: 5px;
        grid-template-columns: 220px 280px 90px auto;
        padding: 10px;
        margin-bottom: -58px;
        position: relative;
        z-index: 3;
    }
} */