a {
    text-decoration: none;
    color: #0000ee;
}

a:hover,
a:active,
a.active {
    color: rgb(0, 112, 192);
}

.iconTrash {
    color: red;
}