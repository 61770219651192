.mapContainer {
    width: 100vw;
    height: 100vh;
    margin-top: -35x;
    z-index: 3;
}

.popup {
    color: darkblue;
    height: 100px;
    width: auto;
    margin-top: 5px;
}

.marker {
    background-color: green;
}

.leafletDivIcon {
    text-align: center;
}

.buttonMap1 {
    z-index: 5;
    position: relative;
}