.Toolbar {
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: #703B09; */
    background-color: rgb(68, 0, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar nav {
    height: 100%;
}

.Logo {
    height: 80%;
}

.Clock {
    position: fixed;
    margin-left: 140px;
}

@media (max-width: 1055px) {
    .DesktopOnly {
        display: none;
    }
}

@media (max-width: 420px) {
    .Clock {
        position: absolute;
        font-size: 12px;
        margin-left: 60px;
    }
}

@media (max-width: 272px) {
    .Clock {
        display: none;
    }
}